import { Injectable } from '@angular/core';
import { SessionService } from '../session.service';

export interface BadgeItem {
  type: string;
  value: string;
}
export interface Saperator {
  name: string;
  type?: string;
}
export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
  recurso?: number
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  saperator?: Saperator[];
  children?: ChildrenItems[];
  recurso?: number;
}
// onlyMaster indica que apenas os usuarios masters podem ver o menu
//icones  -> https://materializecss.com/icons.html

const MENUITEMS: Menu[] = [
  {
    state: '',
    name: 'Personal',
    type: 'saperator',
    icon: 'av_timer',
    recurso: 1,
  },
  // {
  //   state: 'dashboards',
  //   name: 'Dashboards',
  //   type: 'sub',
  //   icon: 'pie_chart',
  //   recurso: 1,
  //   children: [
  //     { state: 'dashboard', name: 'Dashboard ', type: 'link', recurso: 1 },
  //     //{ state: 'indicadores', name: 'Indicadores', type: 'link' }
  //   ]
  // },
  {
    state: 'cadastro',
    name: 'Cadastros',
    type: 'sub',
    icon: 'web',
    recurso: 46,
    children: [
      { state: 'localizacao-armazem', name: 'Armazem', type: 'link', recurso: 90 },
      { state: 'localizacao-baia-impar', name: 'Baias', type: 'link', recurso: 91 },
      { state: 'localizacao-nivel', name: 'Nível', type: 'link', recurso: 92 },
      { state: 'localizacao-rua', name: 'Rua', type: 'link', recurso: 93 },
      { state: 'tag-produto', name: 'Características Adicionais', type: 'link', recurso: 70 },
      { state: 'configurar-kit', name: 'Configurar Kits', type: 'link', recurso: 24 },
      { state: 'depositante', name: 'Depositantes', type: 'link', recurso: 85 },
      { state: 'destinatario', name: 'Destinatários', type: 'link', recurso: 87 },
      { state: 'divisao', name: 'Divisões', type: 'link', recurso: 87 },
      { state: 'categoria-produto', name: 'Família de produtos', type: 'link', recurso: 56 },
      { state: 'categoria-produto-padrao', name: 'Família de produtos padrão', type: 'link', recurso: 55 },
      { state: 'fornecedor', name: 'Fornecedores', type: 'link', recurso: 86 },
      { state: 'unidade-medida', name: 'Unidades de Medida', type: 'link', recurso: 86 },
    ]
  },
  {
    state: 'produtos',
    name: 'Produtos',
    type: 'sub',
    icon: 'widgets',
    recurso: 16,
    children: [
      { state: 'tags', name: 'Associar Características Adicionais', type: 'link', recurso: 70 },
      { state: 'gerenciar-produto', name: 'Cadastro de produtos', type: 'link', recurso: 57 },
      { state: 'catalogo', name: 'Catálogo de Produtos', type: 'link', recurso: 83 },
      { state: 'entrada-produto', name: 'Entrada de Produtos', type: 'link', recurso: 50 }
    ]
  },
  {
    state: 'estoque',
    name: 'Estoque',
    type: 'sub',
    icon: 'move_up',
    recurso: 16,
    children: [
      { state: 'gerenciar-solicitacao-produto-estoque', name: 'Gerenciar Solicitação de Produtos', type: 'link', recurso: 68 },
      // { state: 'ajuste-estoque', name: 'Manutenção de Estoque', type: 'link', recurso: 95 },
      { state: 'minha-solicitacao-produto-estoque', name: 'Meu Histórico de Solicitações', type: 'link', recurso: 66 },
      { state: 'solicitar-produto-estoque', name: 'Solicitar produtos ao MKT', type: 'link', recurso: 66 },
      { state: 'transferencia-divisao', name: 'Transferir Produtos entre divisões', type: 'link', recurso: 53 },
      { state: 'transferencia-localizacao', name: 'Transferir Produtos entre localizações', type: 'link', recurso: 54 },
      { state: 'configurar-divisao', name: 'Configurar Divisões', type: 'link', recurso: 103 },
      //TODO: Ajustar valor do recurso baseado no acesso do usuário
      { state: 'consultar-estoque', name: 'Consultar Estoque', type: 'link', recurso: 103}
    ]
  },
  {
    state: 'saldo-virtual',
    name: 'Saldo Virtual',
    type: 'sub',
    icon: 'grain',
    recurso: 59,
    children: [
      { state: 'gerenciar', name: 'Gerenciar/Estornar Saldo Virtual', type: 'link', recurso: 73 },
      { state: 'gerenciar-solicitacao-saldo', name: 'Gerenciar Solicitações da Equipe', type: 'link', recurso: 67 },
      { state: 'minha-solicitacao-saldo', name: 'Meu Histórico de Solicitações', type: 'link', recurso: 65 },
      { state: 'distribuir-produto', name: 'Rateio de produtos para equipe', type: 'link', recurso: 60 },
      { state: 'solicitar-saldo', name: 'Solicitar saldo', type: 'link', recurso: 65 },
      { state: 'transferir-saldo', name: 'Transferir saldo entre equipe', type: 'link', recurso: 61 },
    ]
  },
  {
    state: 'pedidos',
    name: 'Pedidos',
    type: 'sub',
    badge: [{ type: 'red', value: '17' }],
    icon: 'local_mall',
    recurso: 26,
    children: [
      { state: 'aprovarpedidos', name: 'Aprovar Pedidos', type: 'link', recurso: 28 },
      { state: 'consultarpedidos', name: 'Consultar Pedidos', type: 'link', recurso: 27 },
      // { state: 'planilhas', name: 'Planilhas', type: 'link', recurso: 39 },
      { state: 'solicitar-kit', name: 'Solicitar Kits', type: 'link', recurso: 25 },
    ]
  },
  {
    state: 'relatorios',
    name: 'Relatórios',
    type: 'sub',
    icon: 'description',
    recurso: 42,
    children: [
      { state: 'categoria', name: 'Categorias', type: 'link', recurso: 44, },
      { state: 'configurar-relatorio', name: 'Configurar Relatórios Gerenciais', type: 'link', recurso: 47, },
      { state: 'gerencial', name: 'Relatórios Gerenciais', type: 'link', recurso: 43, },
      // { state: 'tipo-lista', name: 'Tipos de Listas', type: 'link', recurso: 45, },
      //  { state: 'envios', name: 'Envios', type: 'link', onlyMaster: true },
      //  { state: 'recebimentos', name: 'Recebimentos', type: 'link', onlyMaster: true }
    ]
  },
  {
    state: 'seguranca',
    name: 'Segurança',
    type: 'sub',
    icon: 'verified_user',
    recurso: 34,
    children: [
      { state: 'configurar-perfil-usuario', name: 'Configurar Perfil de Usuário', type: 'link', recurso: 48 },
      { state: 'configurar-usuario', name: 'Configurar usuários', type: 'link', recurso: 35 },
      // { state: 'perfil-usuario', name: 'Perfis de Usuário', type: 'link', recurso: 36 },
      { state: 'usuario', name: 'Usuários', type: 'link', recurso: 36 },
    ]
  },
  {
    state: 'ajuda',
    name: 'Ajuda',
    type: 'sub',
    icon: 'help',
    recurso: 40,
    children: [
      { state: 'manual', name: 'Manual do sistema', type: '_blank', recurso: 41 },
    ]
  }
];


@Injectable()
export class MenuItems {
  constructor(private session: SessionService) {

  }

  getMenuitem(): Menu[] {

    for (let i = 0; i < MENUITEMS.length; i++) {
      if (!this.session.possuiAcessoRecurso(MENUITEMS[i].recurso)) {
        MENUITEMS.splice(i, 1);

      }

      if (MENUITEMS[i].children) {
        for (let x = 0; x < MENUITEMS[i].children.length; x++) {
          if (!this.session.possuiAcessoRecurso(MENUITEMS[i].children[x].recurso)) {
            MENUITEMS[i].children.splice(x, 1);

          }
        }

        //Remove menus que não possuem mais filhos
        if (MENUITEMS[i].children.length === 0) {
          MENUITEMS.splice(i, 1);

        }
      }
    }

    return MENUITEMS;
  }
}
