import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

import * as XLSX from 'xlsx';

import { ComponentBase } from '../../shared/component-base';
import { SessionService } from '../../shared/session.service';
import { ProdutoService } from '../produto.service';
import { AlertService } from '../../shared/alert.service';
import { consultarCoordenadorDivisaoResponseEntity } from '../entities/response/consultar-coordenador-divisao-response.entity';
import { consultarEquipeCoordenadorDivisaoResponseEntity } from '../entities/response/consultar-equipe-coordenador-divisao-response.entity';
import { consultarProdutosVendedorResponseEntity } from '../entities/response/produto-vendedor-response.entity';
import { resultadoPesquisaConsultaEstoqueEntity } from '../entities/resultado-pesquisa-consulta-estoque.entity';

interface EstadoUI {
  exibirBotaoExportarExcel: boolean;
  exibirBotaoLimparFiltro: boolean;
  exibirDropdownGrid: boolean;
}

@Component({
  selector: 'app-produto-consultar-estoque',
  templateUrl: './produto-consultar-estoque.component.html',
  styleUrls: ['./produto-consultar-estoque.component.css']
})

export class ProdutoConsultarEstoqueComponent extends ComponentBase implements OnInit {
  pesquisaDivisao: string;
  resultadoConsultaCoordenadores: consultarCoordenadorDivisaoResponseEntity[] = [];
  divisoesFiltradas: { id: number, descricao: string, nomeCoordenador: string }[] = [];
  equipeCoordenador: consultarEquipeCoordenadorDivisaoResponseEntity[] = [];
  nomeCoordenador: string;
  nomeDivisao: string;
  idDivisaoSelecionada: number;
  estadoUI: EstadoUI = {
    exibirBotaoExportarExcel: false,
    exibirBotaoLimparFiltro: false,
    exibirDropdownGrid: false
  };
  resultado: resultadoPesquisaConsultaEstoqueEntity = {
    idDivisao: 0,
    nomeCoordenadorDiv: '',
    nomeDivisao: '',
    equipe: []
  };

  constructor(
    private cdr: ChangeDetectorRef,
    private produtoService: ProdutoService,
    protected alert: AlertService,
    public session: SessionService
  ) {
    super();
  }

  ngOnInit() {
  }

  consultarEstoque() {
    this.limparFiltro(false);

    if (!this.pesquisaDivisao || this.pesquisaDivisao.trim().length < 3) {
      this.alert.warning("Por favor, insira ao menos 3 caracteres para a pesquisa.");
      return;
    }

    this.consultarCoordenadorDivisao(this.pesquisaDivisao);

    this.atualizarEstadoUI();
  }

  onDivisaoSelecionada(idDivisao: number): void {
    this.idDivisaoSelecionada = idDivisao;

    const divisao = this.resultadoConsultaCoordenadores.find(d => d.idDivisaoCoordenador === idDivisao);
    if (divisao) {
      this.nomeCoordenador = divisao.descricaoCoordenador;
      this.pesquisaDivisao = divisao.descricaoDivisaoCoordenador;
      this.nomeDivisao = divisao.descricaoDivisaoCoordenador;

      this.consultarEquipeCoordenador(idDivisao);
      this.atualizarEstadoUI();
    } else {
      this.alert.warning('Nenhum coordenador encontrado para a divisão selecionada!');
    }
    this.atualizarEstadoUI();
  }

  carregarProdutosDoVendedor(idVendedor: number, index: number): void {
    if (this.resultado.equipe[index].produtos.length > 0) {
      return;
    }

    this.consultarProdutosVendedor(idVendedor, index, true);
  }

  limparFiltro(limpaCampo: boolean) {
    if (limpaCampo) {
      this.pesquisaDivisao = '';
    }
    this.resultadoConsultaCoordenadores = [];
    this.divisoesFiltradas = [];
    this.equipeCoordenador = [];
    this.resultado = {
      idDivisao: 0,
      nomeCoordenadorDiv: '',
      nomeDivisao: '',
      equipe: []
    };

    this.nomeCoordenador = '';
    this.nomeDivisao = '';
    this.idDivisaoSelecionada = null;
    this.estadoUI.exibirBotaoExportarExcel = false;
    this.estadoUI.exibirDropdownGrid = false;
    this.estadoUI.exibirBotaoLimparFiltro = false;
    this.atualizarEstadoUI();
  }

  adicionarDivisoesFiltradas(resultadoConsultaCoordenadores: consultarCoordenadorDivisaoResponseEntity[]) {
    const uniqueDivisoesIds = new Set<number>();
    resultadoConsultaCoordenadores.forEach((item) => {
      if (!uniqueDivisoesIds.has(item.idDivisaoCoordenador)) {
        uniqueDivisoesIds.add(item.idDivisaoCoordenador);
        this.divisoesFiltradas.push({ id: item.idDivisaoCoordenador, descricao: item.descricaoDivisaoCoordenador, nomeCoordenador: item.descricaoCoordenador });
      }
    });
  }

  async exportarExcel() {
    await this.consultarEquipeCoordenador(this.idDivisaoSelecionada);
    const exportData: any[] = [];

    this.resultado.equipe.forEach(async (vendedor, index) => {
      const coordenador = this.resultado.nomeCoordenadorDiv;
      await this.consultarProdutosVendedor(vendedor.idPessoa, index, false);

      if (vendedor.produtos.length === 0) {
        exportData.push({
          'Nome do Coordenador': coordenador,
          'Nome do Vendedor': vendedor.descricaoPessoa,
          'Código do Produto': '',
          'Descrição do Produto': '',
          'Canal': '',
          'Marca': '',
          'Linha de Produto': '',
          'Categoria': '',
          'Saldo': ''
        });
      } else {
        vendedor.produtos.forEach(produto => {
          exportData.push({
            'Nome do Coordenador': coordenador,
            'Nome do Vendedor': vendedor.descricaoPessoa,
            'Código do Produto': produto.codigo,
            'Descrição do Produto': produto.descricao,
            'Canal': produto.canal,
            'Marca': produto.marca,
            'Linha de Produto': produto.linhaProduto,
            'Categoria': produto.categoria,
            'Saldo': produto.saldo
          });
        });
      }
    });

    setTimeout(() => {
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportData);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Produtos por Vendedor');
      XLSX.writeFile(wb, 'Produtos_por_Vendedor.xlsx');
    }, 1000);
  }

  private consultarCoordenadorDivisao(pesquisa: string) {
    this.loading2<consultarCoordenadorDivisaoResponseEntity[]>(() => this.produtoService.consultarCoordenadorDivisao(pesquisa)
    ).subscribe(
      (resp) => {
        if (resp && resp.length > 0) {
          this.resultadoConsultaCoordenadores = resp;

          this.adicionarDivisoesFiltradas(this.resultadoConsultaCoordenadores);
          this.estadoUI.exibirDropdownGrid = !this.estadoUI.exibirDropdownGrid;
          this.atualizarEstadoUI();
        } else {
          this.alert.warning(`Não foram encontrados resultados para a pesquisa: "${pesquisa}".\nPor favor, tente outros termos.`);
        }
      },
      (error) => {
        this.alert.error('Erro ao carregar dados da pesquisa.');
      }
    );
  }

  private consultarEquipeCoordenador(selectedId: number): Promise<consultarEquipeCoordenadorDivisaoResponseEntity[]> {
    return new Promise((resolve, reject) => {
      this.loading2<consultarEquipeCoordenadorDivisaoResponseEntity[]>(() =>
        this.produtoService.consultarEquipeCoordenadorDivisao(selectedId)
      ).subscribe(
        (resp) => {
          if (resp && resp.length > 0) {
            this.equipeCoordenador = resp;

            this.resultado.equipe = resp.map(coordenador => ({
              idPessoa: coordenador.idPessoa,
              descricaoPessoa: coordenador.descricaoPessoa,
              produtos: []
            }));

            this.estadoUI.exibirDropdownGrid = false;
            this.estadoUI.exibirBotaoExportarExcel = true;
            this.estadoUI.exibirBotaoLimparFiltro = true;
            this.atualizarEstadoUI();
            
            let divisao = this.resultadoConsultaCoordenadores.find(p => p.descricaoDivisaoCoordenador && p.idDivisaoCoordenador === selectedId);
            if (divisao) {
              this.resultado.nomeDivisao = divisao.descricaoDivisaoCoordenador;
              this.resultado.nomeCoordenadorDiv = divisao.descricaoCoordenador;
            }

            resolve(resp);
          } else {
            this.alert.warning(`Não foram encontrados resultados para o id do coordenador pesquisado: "${selectedId}"`);
            resolve([]);
          }
        },
        (error) => {
          this.alert.error('Erro ao carregar dados da pesquisa.');
          reject(error);
        }
      );
    });
  }

  private consultarProdutosVendedor(idVendedor: number, index: number, showAlert: boolean): Promise<consultarProdutosVendedorResponseEntity[]> {
    return new Promise((resolve, reject) => {
      this.loading2<consultarProdutosVendedorResponseEntity[]>(() =>
        this.produtoService.consultarProdutosVendedor(idVendedor)
      ).subscribe(
        (resp) => {
          if (resp && resp.length > 0) {
            this.resultado.equipe[index].produtos = resp.map(produto => ({
              idProduto: produto.idProduto,
              codigo: produto.sku,
              descricao: produto.descricao,
              canal: produto.canal,
              marca: produto.marca,
              linhaProduto: produto.linhaProduto,
              categoria: produto.categoria,
              saldo: produto.saldo
            }));
            resolve(resp);
          } else {
            if (showAlert) {
              this.alert.warning('Nenhum produto encontrado para este vendedor.');
            }
            resolve([]);
          }
        },
        (error) => {
          this.alert.error('Erro ao carregar produtos do vendedor.');
          reject(error);
        }
      );
    });
  }

  private atualizarEstadoUI(): void {
    this.cdr.detectChanges();
  }
}



